import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'
import { ProductBadge } from 'src/components/ProductBadge/ProductBadge'
import { ProductCardImage } from 'src/components/ProductCard/ProductCardImage'
import { ProductCardPrice } from 'src/components/ProductCard/ProductCardPrice'
import { ProductSchema } from 'src/components/ProductSchema/ProductSchema'
import { Rating } from 'src/components/Rating/Rating'
import { RoastDegree } from 'src/components/RoastDegree/RoastDegree'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'
import { useLocale } from 'src/contexts/LocaleContext'
import { getCalculatedPrices } from 'src/functions/price/getCalculatedPrices'
import { getBadgeVariant } from 'src/functions/product/getBadgeVariant'
import { getCheapestVariant } from 'src/functions/product/getCheapestVariant'
import { removeBioOrganic } from 'src/functions/product/removeBioOrganic'
import { getMetafield } from 'src/functions/util/getMetafield'
import { pathHelper } from 'src/functions/util/pathHelper'
import { PRODUCT_CARD } from 'src/selectors'

import styles from './ProductCard.module.scss'

import type { Image, Product, ProductVariant } from 'src/data/graphql-generated'
import type { ProductRating } from 'src/types/Product'

interface ProductCardProps {
  product: Product
  variant?: ProductVariant
  rating?: ProductRating
  preload?: boolean
  isInsideCarousel?: boolean
}

export const ProductCard = ({
  product,
  variant,
  rating,
  preload = false,
  isInsideCarousel = false,
}: ProductCardProps) => {
  const { t } = useTranslation()
  const { language, locale } = useLocale()

  const {
    title,
    handle,
    productType,
    availableForSale,
    seo,
    vendor,
    variants: { nodes: productVariants },
    metafields,
  } = product

  const [mainVariant] = productVariants
  const displayedVariant = variant || mainVariant

  if (getMetafield(displayedVariant.metafields, 'product', 'archived')) {
    return null
  }

  if (getMetafield(metafields, 'b2b', 'exclusive')) {
    return null
  }

  const displayedPriceVariant = variant || getCheapestVariant(productVariants)
  const { title: variantTitle, sku, barcode, image, availableForSale: variantAvailableForSale } = displayedVariant
  const calculatedPrices = getCalculatedPrices({ variant: displayedPriceVariant })

  const badgeVariant = getBadgeVariant(product, true)

  const hasMultipleVariants = productVariants.length > 1
  const productTitleWithoutBio = removeBioOrganic(title)
  const fullName = `${productTitleWithoutBio} ${variant && hasMultipleVariants ? variantTitle : ''}`
  const path = pathHelper(productType, language, handle, sku, !!variant, hasMultipleVariants)

  // if no specific variant is selected, availability must come from the product and not the main variant
  const isAvailable = variant ? variantAvailableForSale : availableForSale

  const coffeeTaste = getMetafield(metafields, 'coffee', 'taste', locale)
    ?.map((taste: Record<string, string>) => taste?.title)
    .join(', ')
  const coffeeRoastDegree = getMetafield(metafields, 'coffee', 'roast_degree')
  const coffeeCharacterAdjective = getMetafield(metafields, 'coffee', 'character_adjective', locale)

  const hasCoffeeMetadata = coffeeTaste && coffeeCharacterAdjective
  const showVariantTitle = !hasCoffeeMetadata && variant && hasMultipleVariants

  const mpn = getMetafield(displayedVariant.metafields, 'product', 'mpn')

  const deliveryTimeOverride = getMetafield(displayedVariant.metafields, 'product', 'delivery_time')
  const [minDeliveryTime, maxDeliveryTime] = (deliveryTimeOverride || config.deliveryTime).split('-')

  const hoverImage = getMetafield(metafields, 'images', 'hover')

  return (
    <div
      className={clsx(styles.cardWrapper, isInsideCarousel && styles.isInsideCarousel)}
      data-testid={sku}
      data-cy={PRODUCT_CARD}
    >
      <a
        href={path}
        className={styles.cardContainer}
        data-ordernumber={sku}
        aria-label={`${productTitleWithoutBio} ${calculatedPrices.price.amount} ${calculatedPrices.price.currencyCode}`}
      >
        <div>
          <ProductBadge badgeVariant={badgeVariant} />
          <ProductCardImage
            name={fullName}
            image={image || (mainVariant.image as Image)}
            hover={hoverImage?.image}
            preload={preload}
          />
          {!!rating && !isInsideCarousel && (
            <div className={styles.ratingWrapper}>
              <Rating rating={rating.average} size="sm" />
              <Text variant="span" x-small>
                {rating.count > 0 && rating.count}
              </Text>
            </div>
          )}
          <Headline variant="sub" className={styles.cardHeadline}>
            {productTitleWithoutBio}
          </Headline>
          {hasCoffeeMetadata && (
            <Text small className={styles.info}>
              {coffeeCharacterAdjective.title} | {coffeeTaste}
            </Text>
          )}
          {showVariantTitle && (
            <Text small className={styles.info}>
              {variantTitle}
            </Text>
          )}
        </div>
        <div>
          {Boolean(coffeeRoastDegree) && (
            <div className={styles.roastDegreeContainer}>
              <div className={styles.roastDegreeWrapper}>
                <Text small className={styles.textWrapper}>
                  {t('coffee.roastDegree')}
                </Text>
                <RoastDegree value={coffeeRoastDegree} centered />
              </div>
            </div>
          )}
          <ProductCardPrice productVariant={displayedVariant} isStartingPrice={hasMultipleVariants && !variant} />
        </div>
        <ProductSchema
          name={fullName}
          image={image?.url}
          description={seo.description}
          path={pathHelper(productType, language, handle)}
          brand={vendor}
          priceNumeric={calculatedPrices.price.amount}
          ratingValue={rating?.average ?? 0}
          ratingCount={rating?.count ?? 0}
          gtin13={barcode}
          mpn={mpn}
          isItemAvailable={isAvailable}
          minDeliveryTime={minDeliveryTime}
          maxDeliveryTime={maxDeliveryTime}
        />
      </a>
    </div>
  )
}
